//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const { Conflux, Drip } = require("js-conflux-sdk");
import draggable from "vuedraggable";
import { fabric } from "fabric";
import "@/libs/eraser_brush.mixin";
export default {
  name: "ProPainting",

  data() {
    return {
      conflux: null,
      track: "",
      w: document.body.clientWidth,
      h: document.body.clientHeight,

      showbox2: false,
      showbox: false,
      title: "",
      price: "",
      picname: "",
      desc: "",
      hash: "",
      signanme: "",

      fg: 0,

      vpt4: 0, // 初始值
      vpt5: 0,
      offset4: 0,
      offset5: 0,

      canvasw: document.body.clientWidth,
      canvash: document.body.clientHeight,

      active: false,

      // 图层操作层
      layerShow: true,

      // 线稿层
      lineLayerGroupIndex: 0, // 当前index
      lineLayerGroup: [{ id: 0, name: "layer", eye: true }],
      lineLayerSteps: [], // 每步操作对象 { id: 0, obj: null, gropuid: 0, eye: true }

      lineShow: false, // 线稿成品层显示 铸造 或 转图层
      layerArray2: [],

      canvas: null, // fabric canvas对象
      bgColor: "rgba(255,255,255,.9)", // 背景色
      strokeColor: "#000000", // 线框色
      lineSize: 1, // 线条大小 （线条 and 线框）
      fontSize: 18, // 字体大小
      transparency: 100, // 画笔透明度

      selectTool: "brush ", // 当前用户选择的绘图工具 画笔：brush 直线：line 矩形：rect 圆形 circle 文本 text
      zoom: 1,

      // 图形
      fillColor: "rgba(255,255,255,0)", // 填充色
      mouseFrom: {}, // 鼠标绘制起点
      mouseTo: {}, // 鼠标绘制重点
      drawingObject: null, // 保存鼠标未松开时用户绘制的临时图像

      isDrawing: false, // 当前是否正在绘制图形（画笔，文本模式除外）
      stateArr: [], // 保存画布的操作记录 当前可退
      stateIdx: 0, // 当前操作步数
      isRedoing: false, // 撤销或重做 false不录入历史记录  true录入

      /* ========================= 色彩层 */
      layerShow2: true,
      lineLayerGroupIndex2: 0, // 当前index
      lineLayerGroup2: [{ id: 0, name: "layer", eye: true }],
      lineLayerSteps2: [], // 每步操作对象 { id: 0, obj: null, gropuid: 0, eye: true }

      canvas2: null,
      strokeColor2: "#cc0000", // 线框色
      bgColor2: "rgba(255,255,255,0.9)", // 背景色
      lineSize2: 7, // 线条大小 （线条 and 线框）
      fontSize2: 18, // 字体大小
      transparency2: 100, // 画笔透明度

      selectTool2: "brush ", // 当前用户选择的绘图工具 画笔：brush 直线：line 矩形：rect 圆形 circle 文本 text
      zoom2: 1,

      isDrawing2: false, // 当前是否正在绘制图形（画笔，文本模式除外）
      stateArr2: [], // 保存画布的操作记录 当前可退
      stateIdx2: 0, // 当前操作步数
      isRedoing2: false, // 撤销或重做 false不录入历史记录  true录入
      show: false,

      canvasmeng: null,
      cutleft: 0,
      cuttop: 0,
      cutw: 0,
      cuth: 0,

      cfxtype: "0",
    };
  },
  components: {
    draggable,
  },
  watch: {
    // 监听线条大小变化
    lineSize() {
      this.canvas.freeDrawingBrush.width = parseInt(this.lineSize, 10);
      this.lineSize = parseInt(this.lineSize, 10);
    },
    lineSize2() {
      this.canvas2.freeDrawingBrush.width = parseInt(this.lineSize2, 10);
      this.lineSize2 = parseInt(this.lineSize2, 10);
    },
    transparency2() {
      const brush = this.canvas2.freeDrawingBrush;
      brush.color =
        this.hexToRgba(this.strokeColor2, parseFloat(this.transparency2 / 100, 2)) || 1;
    },
    strokeColor2() {
      const brush = this.canvas2.freeDrawingBrush;
      brush.color =
        this.hexToRgba(this.strokeColor2, parseFloat(this.transparency2 / 100, 2)) || 1;
    },
    canvasw() {
      localStorage.setItem("canvasw", this.canvasw);
      document.getElementById("www").style.width = this.canvasw + "px";
      if (this.canvasw < this.w - 240) {
        this.$refs.hb.style.width = this.canvasw + "px";
        this.$refs.hb2.style.width = this.canvasw + "px";
        this.$refs.cutmengbox.style.width = this.canvasw + "px";
      } else {
        this.$refs.hb.style.width = this.w - 240 + "px";
        this.$refs.hb2.style.width = this.w - 240 + "px";
        this.$refs.cutmengbox.style.width = this.w - 240 + "px";
      }
    },
    canvash() {
      localStorage.setItem("canvash", this.canvash);
      document.getElementById("www2").style.height = this.canvash + "px";
      if (this.canvash < this.h - 100) {
        this.$refs.hb.style.height = this.canvash + "px";
        this.$refs.hb2.style.height = this.canvash + "px";
        this.$refs.cutmengbox.style.height = this.canvash + "px";
      } else {
        this.$refs.hb.style.height = this.h - 100 + "px";
        this.$refs.hb2.style.height = this.h - 100 + "px";
        this.$refs.cutmengbox.style.height = this.h - 100 + "px";
      }
    },
  },
  mounted() {
    // 获取MD5验证码 (防抓包加密)
    let timeuid = "";
    if (!localStorage.getItem("_timeuid")) {
      timeuid = new Date().getTime();
      localStorage.setItem("_timeuid", timeuid);
    } else {
      timeuid = localStorage.getItem("_timeuid");
    }
    this.$axios
      .put(
        this.$store.state.api + "/track",
        this.$qs.stringify({
          id: timeuid,
        })
      )
      .then((response) => {
        this.track = response.data;
      });
    window.Shortcutkey = this.Shortcutkey;
    //初始化宽高
      if (localStorage.getItem("canvasw")) {
        this.canvasw = localStorage.getItem("canvasw");
        document.getElementById("www").style.width = this.canvasw + "px";
        if (this.canvasw < this.w - 240) {
          this.$refs.hb.style.width = this.canvasw + "px";
          this.$refs.hb2.style.width = this.canvasw + "px";
          this.$refs.cutmengbox.style.width = this.canvasw + "px";
        } else {
          this.$refs.hb.style.width = this.w - 240 + "px";
          this.$refs.hb2.style.width = this.w - 240 + "px";
          this.$refs.cutmengbox.style.width = this.w - 240 + "px";
        }
      }
      if (localStorage.getItem("canvash")) {
        this.canvash = localStorage.getItem("canvash");
        document.getElementById("www2").style.height = this.canvash + "px";
        if (this.canvash < this.h - 100) {
          this.$refs.hb.style.height = this.canvash + "px";
          this.$refs.hb2.style.height = this.canvash + "px";
          this.$refs.cutmengbox.style.height = this.canvash + "px";
        } else {
          this.$refs.hb.style.height = this.h - 100 + "px";
          this.$refs.hb2.style.height = this.h - 100 + "px";
          this.$refs.cutmengbox.style.height = this.h - 100 + "px";
        }
      }
      
    setTimeout(() => {
      this.lineLayerGroup = localStorage.getItem("GROUP")
        ? JSON.parse(localStorage.getItem("GROUP"))
        : [{ id: 0, name: "layer", eye: true }];

      const lineLayerSteps = localStorage.getItem("STEPS")
        ? JSON.parse(localStorage.getItem("STEPS"))
        : [];
      //console.log(lineLayerSteps);
      let canvasString = '{"objects":[';
      for (let roomObjectKey in lineLayerSteps) {
        canvasString += JSON.stringify(lineLayerSteps[roomObjectKey].obj) + ",";
      }
      canvasString = canvasString.substring(0, canvasString.lastIndexOf(","));
      canvasString += '],"background":"' + this.bgColor + '"}';
      //console.log(canvasString);
      this.canvas.loadFromJSON(canvasString);
      const tt = this.canvas.getObjects();
      this.lineLayerSteps = [];
      for (let index = 0; index < tt.length; index++) {
        const element = tt[index];
        this.lineLayerSteps.push({
          id: lineLayerSteps[index].id,
          obj: element,
          gropuid: lineLayerSteps[index].gropuid,
          eye: lineLayerSteps[index].eye,
        });
      }
      this.canvas.clear();
      this.canvas.loadFromJSON(localStorage.getItem("TT"));
      this.canvas.renderAll();

      this.lineLayerGroup2 = localStorage.getItem("GROUP2")
        ? JSON.parse(localStorage.getItem("GROUP2"))
        : [{ id: 0, name: "layer", eye: true }];

      const lineLayerSteps2 = localStorage.getItem("STEPS2")
        ? JSON.parse(localStorage.getItem("STEPS2"))
        : [];
      // 不能转化 klass
      let canvasString2 = '{"objects":[';
      for (let roomObjectKey in lineLayerSteps2) {
        canvasString2 += JSON.stringify(lineLayerSteps2[roomObjectKey].obj) + ",";
      }
      canvasString2 = canvasString2.substring(0, canvasString2.lastIndexOf(","));
      canvasString2 += '],"background":"' + this.bgColor2 + '"}';
      //console.log(canvasString2);

      this.canvas2.loadFromJSON(canvasString2);
    }, 500);

    setTimeout(() => {
      //console.log(this.canvas2.getObjects());
      const tt2 = this.canvas2.getObjects();
      const lineLayerSteps2 = localStorage.getItem("STEPS2")
        ? JSON.parse(localStorage.getItem("STEPS2"))
        : [];
      for (let index = 0; index < tt2.length; index++) {
        const element = tt2[index];
        this.lineLayerSteps2.push({
          id: lineLayerSteps2[index].id,
          obj: element,
          gropuid: lineLayerSteps2[index].gropuid,
          eye: lineLayerSteps2[index].eye,
        });
      }
      //console.log(this.lineLayerSteps2);
      //this.lineLayerSteps2 = tt2;

      this.canvas2.clear();
      this.canvas2.loadFromJSON(localStorage.getItem("TT2"));
      this.canvas2.renderAll();
      
    }, 1000);

    // 初始化 画布
    this.initCanvas();
    this.initBruch2();
    // 默认开启画笔模式
    this.tapToolBtn("brush");
    this.$refs.pen.style.color = "red";
    // 初始化 画布 事件
    this.initCanvasEvent();
    this.initCanvasEvent2();

    this.cutCover();
    this.init();
  },
  methods: {
    Shortcutkey(key) {
      switch (key) {
        case "Z":
          this.tapHistoryBtn(-1);
          break;
        case "B":
          this.pen();
          break;

        default:
          break;
      }
    },

    async init() {
      this.conflux = new this.$conflux({
        url: window.RPC_URL,
        networkId: 1029,
        logger: console,
      });
      this.conflux.provider = window.conflux;

      if (this.conflux === "undefined") {
        if (
          confirm(
            "检测到您的浏览器中并未安装conflux钱包插件，点击确定前往下载。\n\n 注：如果没有跳转看下是不是被浏览器拦截了"
          )
        ) {
          window.open("https://github.com/Conflux-Chain/conflux-portal/releases");
        }
      } else {
        const contract = this.conflux.Contract({
          abi: CONTRACT_ABI,
          address: CONTRACT_ADDRESS,
          //address: "0x8067244e0b629610eb24cc9da4fe8b023fc02fd9",
        });
        //console.log(contract);
      }
    },
    handleScroll(e) {
      //let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      let vpt = this.canvas.viewportTransform;
      let vpt2 = this.canvas2.viewportTransform;
      this.offset4 = e.target.scrollLeft;
      vpt[4] = this.vpt4 - e.target.scrollLeft;
      vpt2[4] = this.vpt4 - e.target.scrollLeft;
      this.canvas.renderAll();
      this.canvas2.renderAll();
    },
    handleScroll2(e) {
      let vpt = this.canvas.viewportTransform;
      let vpt2 = this.canvas2.viewportTransform;
      this.offset5 = e.target.scrollTop;
      vpt[5] = this.vpt5 - e.target.scrollTop;
      vpt2[5] = this.vpt5 - e.target.scrollTop;
      this.canvas.renderAll();
      this.canvas2.renderAll();
    },
    onClickTabs(name) {
      if (!name) {
        this.$refs.hb.style.zIndex = 2;
        this.$refs.hb2.style.zIndex = 1;
      } else {
        this.$refs.hb.style.zIndex = 1;
        this.$refs.hb2.style.zIndex = 2;
        this.selectTool2 = "brush";
      }
    },
    // 初始化画布
    initCanvas() {
      if (!this.canvas) {
        this.canvas = new fabric.Canvas("c");
        // 设置画布背景色 (背景色需要这样设置，否则拓展的橡皮功能会报错)
        this.canvas.setBackgroundColor(this.bgColor, undefined, {
          erasable: false,
        });
        // 设置背景色不受缩放与平移的影响
        this.canvas.set("backgroundVpt", false);
        // 禁止用户进行组选择
        this.canvas.selection = true;
        // 设置当前鼠标停留在
        this.canvas.hoverCursor = "default";
        // 重新渲染画布
        this.canvas.renderAll();
        // 记录画布原始状态
        this.stateArr.push(JSON.stringify(this.canvas)); // 初始化
        this.stateIdx = 0;

        let vpt = this.canvas.viewportTransform;
        this.vpt4 = vpt[4];
        this.vpt5 = vpt[5];
        //this.canvas.requestRenderAll();
      }
      if (!this.canvas2) {
        this.canvas2 = new fabric.Canvas("c2");
        // 设置画布背景色 (背景色需要这样设置，否则拓展的橡皮功能会报错)
        this.canvas2.setBackgroundColor(this.bgColor2, undefined, {
          erasable: false,
        });
        // 设置背景色不受缩放与平移的影响
        this.canvas2.set("backgroundVpt", false);
        // 禁止用户进行组选择
        this.canvas2.selection = true;
        // 设置当前鼠标停留在
        this.canvas2.hoverCursor = "default";
        // 重新渲染画布
        this.canvas2.renderAll();
        // 记录画布原始状态
        this.stateArr2.push(JSON.stringify(this.canvas2)); // 初始化
        this.stateIdx2 = 0;

        let vpt = this.canvas2.viewportTransform;
        this.vpt4 = vpt[4];
        this.vpt5 = vpt[5];
      }
    },
    // 初始化画布事件
    initCanvasEvent() {
      // 操作类型集合
      let toolTypes = ["brush", "line", "rect", "circle", "text", "move"];

      // 监听鼠标按下事件
      this.canvas.on("mouse:down", (options) => {
        if (this.selectTool != "text" && this.textObject) {
          // 如果当前存在文本对象，并且不是进行添加文字操作 则 退出编辑模式，并删除临时的文本对象
          // 将当前文本对象退出编辑模式
          this.textObject.exitEditing();
          this.textObject.set("backgroundColor", "rgba(0,0,0,0)");
          if (this.textObject.text == "") {
            this.canvas.remove(this.textObject);
          }
          this.canvas.renderAll();
          this.textObject = null;
        }
        // 判断当前是否选择了集合中的操作
        if (toolTypes.indexOf(this.selectTool) != -1) {
          // 记录当前鼠标的起点坐标 (减去画布在 x y轴的偏移，因为画布左上角坐标不一定在浏览器的窗口左上角)
          this.mouseFrom.x = options.e.clientX - this.canvas._offset.left;
          this.mouseFrom.y = options.e.clientY - this.canvas._offset.top;
          // 判断当前选择的工具是否为文本
          if (this.selectTool == "text") {
            // 文本工具初始化
            this.initText();
          } else {
            // 设置当前正在进行绘图 或 移动操作
            this.isDrawing = true;
          }
        }
      });
      // 监听鼠标移动事件
      this.canvas.on("mouse:move", (options) => {
        // 如果当前正在进行绘图或移动相关操作
        if (this.isDrawing) {
          //console.log(this.isDrawing);
          // 记录当前鼠标移动终点坐标 (减去画布在 x y轴的偏移，因为画布左上角坐标不一定在浏览器的窗口左上角)
          this.mouseTo.x = options.e.clientX - this.canvas._offset.left;
          this.mouseTo.y = options.e.clientY - this.canvas._offset.top;
          switch (this.selectTool) {
            case "line":
              // 当前绘制直线，初始化直线绘制
              this.initLine();
              break;
            case "rect":
              // 初始化 矩形绘制
              this.initRect();
              break;
            case "circle":
              // 初始化 绘制圆形
              this.initCircle();
              break;
            case "move":
              // 初始化画布移动
              this.initMove();
          }
        }
      });
      // 监听鼠标松开事件
      this.canvas.on("mouse:up", () => {
        // 如果当前正在进行绘图或移动相关操作
        if (this.isDrawing) {
          // 有新笔画 压入最后一步骤
          const listObjects = this.canvas.getObjects();
          let element = listObjects[listObjects.length - 1];
          element.myid = this.lineLayerSteps.length; // 自定义ID
          //console.log(element);
          this.lineLayerSteps.push({
            id: this.lineLayerSteps.length,
            obj: element,
            gropuid: this.lineLayerGroupIndex,
            eye: true,
          });

          // 清空鼠标移动时保存的临时绘图对象
          this.drawingObject = null;
          // 重置正在绘制图形标志
          this.isDrawing = false;
          // 清空鼠标保存记录
          this.resetMove();
          // 如果当前进行的是移动操作，鼠标松开重置当前视口缩放系数
          if (this.selectTool == "move") {
            this.canvas.setViewportTransform(this.canvas.viewportTransform);
          }

          // 重画元素
          this.canvas.clear();
          this.canvas.setBackgroundColor(this.bgColor, undefined, {
            erasable: false,
          });
          this.lineLayerSteps.forEach((element) => {
            if (element.eye) {
              if (element.gropuid === this.lineLayerGroupIndex) {
                this.canvas.add(element.obj.set("selectable", true)); // 可选择
              } else {
                this.canvas.add(element.obj.set("selectable", false));
              }
            }
          });
          this.canvas.renderAll();

          const json = JSON.stringify(this.canvas);
          localStorage.setItem("TT", json);
          // 声明canvas反序列化使用klass
          const steps = JSON.stringify(this.lineLayerSteps);
          localStorage.setItem("STEPS", steps);
        }
      });
      // 监听画布渲染完成
      this.canvas.on("after:render", () => {
        if (!this.isRedoing) {
          // 当前不是进行撤销或重做操作
          // 在绘画时会频繁触发该回调，所以间隔1s记录当前状态
          if (this.recordTimer) {
            clearTimeout(this.recordTimer);
            this.recordTimer = null;
          }
          this.recordTimer = setTimeout(() => {
            //console.log("压入" + this.stateArr.length);
            this.stateArr.push(JSON.stringify(this.canvas));
            this.stateIdx++;
          }, 100);
        } else {
          // 当前正在执行撤销或重做操作，不记录重新绘制的画布
          this.isRedoing = false;
        }
      });
    },
    // 初始化画笔工具
    initBruch() {
      // 设置绘画模式画笔类型为 铅笔类型
      this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
      // 设置画布模式为绘画模式
      this.canvas.isDrawingMode = true;
      // 设置绘画模式 画笔颜色与画笔线条大小
      this.canvas.freeDrawingBrush.color = this.strokeColor;
      this.canvas.freeDrawingBrush.width = parseInt(this.lineSize, 10);
    },
    // 鼠标移动时 绘制直线
    initLine() {
      // 根据保存的鼠标起始点坐标 创建直线对象
      let canvasObject = new fabric.Line(
        [
          this.getTransformedPosX(this.mouseFrom.x),
          this.getTransformedPosY(this.mouseFrom.y),
          this.getTransformedPosX(this.mouseTo.x),
          this.getTransformedPosY(this.mouseTo.y),
        ],
        {
          fill: this.fillColor,
          stroke: this.strokeColor,
          strokeWidth: this.lineSize,
        }
      );
      // 绘制 图形对象
      this.startDrawingObject(canvasObject, this.canvas);
    },
    // 鼠标移动时 绘制矩形
    initRect() {
      // 计算矩形长宽
      let left = this.getTransformedPosX(this.mouseFrom.x);
      let top = this.getTransformedPosY(this.mouseFrom.y);
      let width = this.mouseTo.x - this.mouseFrom.x;
      let height = this.mouseTo.y - this.mouseFrom.y;
      // 创建矩形 对象
      let canvasObject = new fabric.Rect({
        left: left,
        top: top,
        width: width,
        height: height,
        stroke: this.strokeColor,
        fill: this.fillColor,
        strokeWidth: this.lineSize,
      });
      // 绘制矩形
      this.startDrawingObject(canvasObject, this.canvas);
    },
    // 鼠标移动时 绘制圆形
    initCircle() {
      let left = this.getTransformedPosX(this.mouseFrom.x);
      let top = this.getTransformedPosY(this.mouseFrom.y);
      // 计算圆形半径
      let radius =
        Math.sqrt(
          (this.getTransformedPosX(this.mouseTo.x) - left) *
            (this.getTransformedPosY(this.mouseTo.x) - left) +
            (this.getTransformedPosX(this.mouseTo.y) - top) *
              (this.getTransformedPosY(this.mouseTo.y) - top)
        ) / 2;
      // 创建 原型对象
      let canvasObject = new fabric.Circle({
        left: left,
        top: top,
        stroke: this.strokeColor,
        fill: this.fillColor,
        radius: radius,
        strokeWidth: this.lineSize,
      });
      // 绘制圆形对象
      this.startDrawingObject(canvasObject, this.canvas);
    },
    // 鼠标移动时 画布移动 [未使用]
    initMove() {
      var vpt = this.canvas.viewportTransform;
      vpt[4] += this.mouseTo.x - this.mouseFrom.x;
      vpt[5] += this.mouseTo.y - this.mouseFrom.y;
      this.canvas.requestRenderAll();
      this.mouseFrom.x = this.mouseTo.x;
      this.mouseFrom.y = this.mouseTo.y;
    },
    // 绘图工具点击选择
    tapToolBtn(tool) {
      this.canvas2.isDrawingMode = true;
      if (this.selectTool === tool) return;

      this.refsReset();
      if (tool === "line") this.$refs.line.style.color = "red";
      if (tool === "rect") this.$refs.rect.style.color = "red";
      if (tool === "circle") this.$refs.circle.style.color = "red";

      // 保存当前选中的绘图工具
      this.selectTool = tool;

      // 选择任何工具前进行一些重置工作
      // 禁用画笔模式
      this.canvas.isDrawingMode = false;
      // 禁止图形选择编辑
      let drawObjects = this.canvas.getObjects();
      if (drawObjects.length > 0) {
        drawObjects.map((item) => {
          item.set("selectable", false);
        });
      }
      if (this.selectTool == "brush") {
        // 如果用户选择的是画笔工具，直接初始化，无需等待用户进行鼠标操作
        this.initBruch();
      } else if (this.selectTool == "eraser") {
        // 如果用户选择的是橡皮擦工具，直接初始化，无需等待用户进行鼠标操作
        this.initEraser();
      }
    },
    // 指针状态 需要更改 图层
    select() {
      this.canvas.isDrawingMode = false;
      this.refsReset();

      this.tapToolBtn("select");
      // 当前图层可选
      this.setLayerActive(this.lineLayerGroupIndex);
      this.$refs.select.style.color = "red";
    },
    // 线稿层 画笔
    pen() {
      this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
      this.canvas.isDrawingMode = true;
      this.refsReset();
      this.tapToolBtn("brush");
      this.$refs.pen.style.color = "red";
    },
    tapClearBtn() {
      let children = this.canvas.getObjects();
      if (children.length > 0) {
        this.canvas.remove(...children);

        this.lineLayerSteps = [];
        this.lineLayerGroupIndex = 0; // 当前index
        this.lineLayerGroup = [{ id: 0, name: "layer", eye: true }];
        localStorage.removeItem("TT");
        localStorage.removeItem("STEPS");
        localStorage.removeItem("GROUP");
      }
    },
    // 撤销前进 按钮点击
    tapHistoryBtn(flag) {
      this.isRedoing = true;

      let stateIdx = this.stateIdx + flag;
      console.log("重做第" + stateIdx + "步");
      // 判断是否已经到了第一步操作
      if (stateIdx < 0) return;
      // 判断是否已经到了最后一步操作
      if (stateIdx >= this.stateArr.length) return;
      if (this.stateArr[stateIdx]) {
        this.canvas.loadFromJSON(this.stateArr[stateIdx]);

        if (this.canvas.getObjects().length > 0) {
          this.canvas.getObjects().forEach((item) => {
            item.set("selectable", false);
          });
        }
        this.stateIdx = stateIdx;
      }
    },
    // 点击橡皮擦
    initEraser() {
      this.refsReset();
      this.$refs.eraser.style.color = "red";
      this.canvas.freeDrawingBrush = new fabric.EraserBrush(this.canvas);
      this.canvas.freeDrawingBrush.width = parseInt(this.lineSize, 10);
      this.canvas.isDrawingMode = true;
      this.tapToolBtn("eraser");
    },
    // 图层新建
    addlayer1() {
      this._checkNotSaved();

      const groupLength = this.lineLayerGroup.length; // 图层数量
      this.lineLayerGroup.push({
        id: groupLength,
        name: groupLength,
        eye: true,
      }); // 增加一个新图层
      this.lineLayerGroupIndex = groupLength;
      this._renderAll(groupLength);

      const groups = JSON.stringify(this.lineLayerGroup);
      localStorage.setItem("GROUP", groups);
    },
    // 图层显示/隐藏
    clickEye(i) {
      this._checkNotSaved();

      // 小图层数组显示与否
      if (this.lineLayerGroup[i].eye) {
        this.lineLayerGroup[i].eye = false;
      } else {
        this.lineLayerGroup[i].eye = true;
      }

      // 设置每笔是否显示
      for (let index = 0; index < this.lineLayerSteps.length; index++) {
        const element = this.lineLayerSteps[index];
        if (element.gropuid === i) {
          this.lineLayerSteps[index].eye = this.lineLayerSteps[index].eye ? false : true;
        }
      }

      this._renderAll(i);

      const groups = JSON.stringify(this.lineLayerGroup);
      localStorage.setItem("GROUP", groups);

      const json = JSON.stringify(this.canvas);
      localStorage.setItem("TT", json);
      // 声明canvas反序列化使用klass
      const steps = JSON.stringify(this.lineLayerSteps);
      localStorage.setItem("STEPS", steps);
    },
    // 图层切换 [点击图层小框]
    setLayerActive(i) {
      this._checkNotSaved();

      this._renderAll(i);

      this.lineLayerGroupIndex = i;
      this.stateArr = []; // 切换图层后 清空
      this.stateIdx = 0;
    },
    // 检查当前画布是否存在未保存的元素
    _checkNotSaved() {},
    _renderAll(i) {
      // 重画
      this.canvas.clear();
      this.canvas.setBackgroundColor(this.bgColor, undefined, {
        erasable: false,
      });
      // 重画元素
      this.lineLayerSteps.forEach((element) => {
        if (element.eye) {
          if (element.gropuid === i) {
            this.canvas.add(element.obj.set("selectable", true)); // 可选择
          } else {
            this.canvas.add(element.obj.set("selectable", false));
          }
        }
      });

      this.canvas.renderAll();
    },
    // 图层操作框 显示/隐藏
    tuceng() {
      if (this.layerShow) {
        this.layerShow = false;
      } else {
        this.layerShow = true;
      }
    },
    // 查看线稿层[未用]
    create() {
      if (this.lineShow) {
        this.lineShow = false;
      } else {
        this.lineShow = true;
        setTimeout(() => {
          const left = -this.offset4;
          const top = -this.offset5;
          const width = this.canvasw - 240;
          const height = this.canvash;
          document.getElementById("img1").src = this.canvas.toDataURL({
            left,
            top,
            width,
            height,
          });
          document.getElementById("img2").src = this.canvas2.toDataURL({
            left,
            top,
            width,
            height,
          });
        }, 500);
      }
    },
    // 工具栏状态重置
    refsReset() {
      this.isDrawing = false;
      this.$refs.select.style.color = "";
      this.$refs.pen.style.color = "";
      this.$refs.tuceng.style.color = "";
      this.$refs.eraser.style.color = "";
      this.$refs.circle.style.color = "";
      this.$refs.rect.style.color = "";
      this.$refs.line.style.color = "";
    },
    // 工具栏2颜色选择器
    drawingColorChange() {
      const brush = this.canvas.freeDrawingBrush;
      brush.color = this.strokeColor2;
    },
    // 工具栏顶部透明度
    transparencyChange() {
      const brush = this.canvas.freeDrawingBrush;
      brush.color =
        this.hexToRgba(this.strokeColor, parseFloat(this.transparency / 100, 2)) || 1;
    },
    // 图形绘制时 跟随鼠标移动效果具体逻辑
    startDrawingObject(canvasObject, canvas) {
      // 禁止用户选择当前正在绘制的图形
      canvasObject.selectable = false;
      // 如果当前图形已绘制，清除上一次绘制的图形
      if (this.drawingObject) {
        canvas.remove(this.drawingObject);
      }
      // 将绘制对象添加到 canvas中
      canvas.add(canvasObject);
      // 保存当前绘制的图形
      this.drawingObject = canvasObject;
    },
    // 清空鼠标移动记录 （起点 与 终点）
    resetMove() {
      this.mouseFrom = {};
      this.mouseTo = {};
    },
    // 计算画布移动之后的x坐标点
    getTransformedPosX(x) {
      let zoom = Number(this.canvas.getZoom());
      return (x - this.canvas.viewportTransform[4]) / zoom;
    },
    getTransformedPosY(y) {
      let zoom = Number(this.canvas.getZoom());
      return (y - this.canvas.viewportTransform[5]) / zoom;
    },

    //颜色值转换
    hexToRgba(hex, opacity) {
      return (
        "rgba(" +
        parseInt("0x" + hex.slice(1, 3)) +
        "," +
        parseInt("0x" + hex.slice(3, 5)) +
        "," +
        parseInt("0x" + hex.slice(5, 7)) +
        "," +
        opacity +
        ")"
      );
    },

    //-----------------------------------------------------
    cutCover() {
      this.canvasmeng = new fabric.Canvas("cutmeng");

      // 监听鼠标按下事件
      this.canvasmeng.on("mouse:down", (options) => {
        this.canvasmeng.clear();
        // 记录当前鼠标的起点坐标 (减去画布在 x y轴的偏移，因为画布左上角坐标不一定在浏览器的窗口左上角)
        this.mouseFrom.x = options.e.clientX - this.canvasmeng._offset.left;
        this.mouseFrom.y = options.e.clientY - this.canvasmeng._offset.top;

        this.isDrawing = true;
      });
      // 监听鼠标移动事件
      this.canvasmeng.on("mouse:move", (options) => {
        if (this.isDrawing) {
          // 记录当前鼠标移动终点坐标 (减去画布在 x y轴的偏移，因为画布左上角坐标不一定在浏览器的窗口左上角)
          this.mouseTo.x = options.e.clientX - this.canvasmeng._offset.left;
          this.mouseTo.y = options.e.clientY - this.canvasmeng._offset.top;

          this.cutleft = this.getTransformedPosX(this.mouseFrom.x);
          this.cuttop = this.getTransformedPosY(this.mouseFrom.y);
          this.cutw = this.mouseTo.x - this.mouseFrom.x;
          this.cuth = this.mouseTo.y - this.mouseFrom.y;
          // 创建矩形 对象
          let canvasObject = new fabric.Rect({
            left: this.cutleft,
            top: this.cuttop,
            width: this.cutw,
            height: this.cuth,
            stroke: "rgba(255,0,0,0.2)",
            fill: "rgba(255,255,255,0)",
            strokeWidth: 1,
          });
          console.log(this.cuttop);
          // 绘制矩形
          this.startDrawingObject(canvasObject, this.canvasmeng);
        }
      });
      // 监听鼠标松开事件
      this.canvasmeng.on("mouse:up", () => {
        // 如果当前正在进行绘图或移动相关操作
        if (this.isDrawing) {
          // 清空鼠标移动时保存的临时绘图对象
          this.drawingObject = null;
          // 重置正在绘制图形标志
          this.isDrawing = false;
          // 清空鼠标保存记录
          this.resetMove();
          console.log(this.cuttop);
          console.log(this.cutw);
        }
      });
    },
    initBruch2() {
      // 设置绘画模式画笔类型为 铅笔类型
      this.canvas2.freeDrawingBrush = new fabric.PencilBrush(this.canvas2);
      // 设置画布模式为绘画模式
      this.canvas2.isDrawingMode = true;
      // 设置绘画模式 画笔颜色与画笔线条大小
      this.canvas2.freeDrawingBrush.color = this.strokeColor2;
      this.canvas2.freeDrawingBrush.width = parseInt(this.lineSize2, 10);
    },
    initCanvasEvent2() {
      // 监听鼠标按下事件
      this.canvas2.on("mouse:down", (options) => {
        let toolTypes = ["brush", "text", "move"];
        // 判断当前是否选择了集合中的操作
        if (toolTypes.indexOf(this.selectTool2) != -1) {
          // 记录当前鼠标的起点坐标 (减去画布在 x y轴的偏移，因为画布左上角坐标不一定在浏览器的窗口左上角)
          this.mouseFrom.x = options.e.clientX - this.canvas._offset.left;
          this.mouseFrom.y = options.e.clientY - this.canvas._offset.top;
          // 判断当前选择的工具是否为文本
          if (this.selectTool2 == "text") {
            // 文本工具初始化
            //this.initText();
          } else {
            // 设置当前正在进行绘图 或 移动操作
            this.isDrawing2 = true;
          }
        }
      });
      // 监听鼠标松开事件
      this.canvas2.on("mouse:up", () => {
        // 如果当前正在进行绘图或移动相关操作

        if (this.isDrawing2 && this.canvas2.isDrawingMode) {
          // 有新笔画 压入最后一步骤
          const listObjects = this.canvas2.getObjects();
          const element = listObjects[listObjects.length - 1];
          element.myid = this.lineLayerSteps2.length; // 自定义ID
          this.lineLayerSteps2.push({
            id: this.lineLayerSteps2.length,
            obj: element,
            gropuid: this.lineLayerGroupIndex2,
            eye: true,
          });

          this.isDrawing2 = false;

          /* 重画元素
          this.canvas2.clear();
          this.canvas2.setBackgroundColor(this.bgColor2, undefined, {
            erasable: false,
          });
          this.lineLayerSteps2.forEach((element) => {
            if (element.eye) {
              if (element.gropuid === this.lineLayerGroupIndex2) {
                this.canvas2.add(element.obj.set("selectable", true)); // 可选择
              } else {
                this.canvas2.add(element.obj.set("selectable", false));
              }
            }
          });
          this.canvas2.renderAll();*/

          const json2 = JSON.stringify(this.canvas2);
          localStorage.setItem("TT2", json2);
          // 声明canvas反序列化使用klass
          const steps2 = JSON.stringify(this.lineLayerSteps2);
          localStorage.setItem("STEPS2", steps2);
        }
      });
      // 监听画布渲染完成
      this.canvas2.on("after:render", () => {
        if (!this.isRedoing2) {
          // 当前不是进行撤销或重做操作
          // 在绘画时会频繁触发该回调，所以间隔1s记录当前状态
          if (this.recordTimer) {
            clearTimeout(this.recordTimer);
            this.recordTimer = null;
          }
          this.recordTimer = setTimeout(() => {
            //console.log("压入" + this.stateArr2.length);
            this.stateArr2.push(JSON.stringify(this.canvas2));
            this.stateIdx2++;
          }, 100);
        } else {
          // 当前正在执行撤销或重做操作，不记录重新绘制的画布
          this.isRedoing2 = false;
        }
      });
    },
    tapClearBtn2() {
      let children = this.canvas2.getObjects();
      if (children.length > 0) {
        this.canvas2.remove(...children);

        this.lineLayerSteps2 = [];
        this.lineLayerGroupIndex2 = 0; // 当前index
        this.lineLayerGroup2 = [{ id: 0, name: "layer", eye: true }];
        localStorage.removeItem("TT2");
        localStorage.removeItem("STEPS2");
        localStorage.removeItem("GROUP2");
      }
    },
    refsReset2() {
      this.isDrawing2 = false;

      this.$refs.cutimg.style.color = "";
      this.cutleft = 0;
      this.cuttop = 0;
      this.cutw = 0;
      this.cuth = 0;
      this.canvasmeng.clear();
      document.getElementById("cutmengbox").style.zIndex = 0;

      this.$refs.select2.style.color = "";
      this.$refs.pen2.style.color = "";
      this.$refs.tuceng2.style.color = "";
      this.$refs.eraser2.style.color = "";
    },
    initEraser2() {
      this.refsReset2();
      this.isDrawing = false;
      this.isDrawing2 = false;
      this.$refs.eraser2.style.color = "red";
      this.canvas2.freeDrawingBrush = new fabric.EraserBrush(this.canvas2);
      this.canvas2.freeDrawingBrush.width = parseInt(this.lineSize2, 10);
      this.canvas2.isDrawingMode = true;
    },
    toPhoto() {
      this.refsReset2();
      this.$refs.cutimg.style.color = "red";
      document.getElementById("cutmengbox").style.zIndex = 30;
    },
    // 色彩层 画笔
    pen2() {
      this.refsReset2();
      this.canvas2.freeDrawingBrush = new fabric.PencilBrush(this.canvas2);
      this.canvas2.isDrawingMode = true;
      this.$refs.select2.style.color = "";
      this.$refs.pen2.style.color = "red";

      this.canvas2.freeDrawingBrush.width = parseInt(this.lineSize2, 10);
      this.selectTool2 = "brush";

      const brush = this.canvas2.freeDrawingBrush;
      brush.color =
        this.hexToRgba(this.strokeColor2, parseFloat(this.transparency2 / 100, 2)) || 1;
    },
    // 色彩层 指针状态
    select2() {
      this.canvas2.isDrawingMode = false;
      this.isDrawing2 = false;

      // 当前图层可选
      //this.setLayerActive2(this.lineLayerGroupIndex2);
      this.$refs.select2.style.color = "red";
    },
    tuceng2() {
      if (this.layerShow2) {
        this.layerShow2 = false;
        this.$refs.tuceng2.style.color = "";
      } else {
        this.layerShow2 = true;
        this.$refs.tuceng2.style.color = "red";
      }
    },
    // 绘图工具点击选择
    tapToolBtn2(tool) {
      // 保存当前选中的绘图工具
      this.selectTool = tool;

      // 选择任何工具前进行一些重置工作
      // 禁用画笔模式
      this.canvas2.isDrawingMode = false;
      // 禁止图形选择编辑
      let drawObjects = this.canvas2.getObjects();
      if (drawObjects.length > 0) {
        drawObjects.map((item) => {
          item.set("selectable", false);
        });
      }
      if (this.selectTool == "brush") {
        // 如果用户选择的是画笔工具，直接初始化，无需等待用户进行鼠标操作
        this.initBruch2();
      } else if (this.selectTool == "eraser") {
        // 如果用户选择的是橡皮擦工具，直接初始化，无需等待用户进行鼠标操作
        this.initEraser2();
      }
    },
    // 图层新建
    addlayer2() {
      this._checkNotSaved2();

      // 找出最大值
      let max = this.lineLayerGroup2[0].id;
      for (let i = 0; i < this.lineLayerGroup2.length - 1; i++) {
        max = max < this.lineLayerGroup2[i + 1].id ? this.lineLayerGroup2[i + 1].id : max;
      }
      const groupLength = max + 1; // 图层数量
      this.lineLayerGroup2.push({
        id: groupLength,
        name: groupLength,
        eye: true,
      }); // 增加一个新图层
      //console.log(this.lineLayerGroup2);
      this.lineLayerGroupIndex2 = groupLength;
      this._renderAll2(groupLength); //失效

      const groups2 = JSON.stringify(this.lineLayerGroup2);
      localStorage.setItem("GROUP2", groups2);
    },
    dellayer(id) {
      alert(id);
    },
    dellayer2(id) {
      // 删除图层
      const index = this._indexOf2(id);
      if (index > -1) {
        this.lineLayerGroup2.splice(index, 1);
      }
      this.lineLayerGroupIndex2 = this.lineLayerGroup2.length;

      //删除对应的画板元素
      let newArray = [];
      for (let index = 0; index < this.lineLayerSteps2.length; index++) {
        const element = this.lineLayerSteps2[index];
        //console.log(element.gropuid);
        if (element.gropuid !== id) {
          newArray.push(element);
        }
      }
      this.lineLayerSteps2 = newArray;
      this._renderAll2(0);

      const groups = JSON.stringify(this.lineLayerGroup2);
      localStorage.setItem("GROUP2", groups);

      const json = JSON.stringify(this.canvas2);
      localStorage.setItem("TT2", json);
      // 声明canvas反序列化使用klass
      const steps = JSON.stringify(this.lineLayerSteps2);
      localStorage.setItem("STEPS2", steps);
    },
    _indexOf2(val) {
      for (var i = 0; i < this.lineLayerGroup2.length; i++) {
        if (this.lineLayerGroup2[i].id == val) return i;
      }
      return -1;
    },
    // 图层显示/隐藏
    clickEye2(id) {
      this._checkNotSaved2();

      for (let index = 0; index < this.lineLayerGroup2.length; index++) {
        const element = this.lineLayerGroup2[index];
        if (element.id === id) {
          if (this.lineLayerGroup2[index].eye) {
            this.lineLayerGroup2[index].eye = false;
          } else {
            this.lineLayerGroup2[index].eye = true;
          }
        }
      }

      // 设置每笔是否显示
      for (let index = 0; index < this.lineLayerSteps2.length; index++) {
        const element = this.lineLayerSteps2[index];
        if (element.gropuid === id) {
          this.lineLayerSteps2[index].eye = this.lineLayerSteps2[index].eye
            ? false
            : true;
        }
      }

      this._renderAll2(0);

      const groups = JSON.stringify(this.lineLayerGroup2);
      localStorage.setItem("GROUP2", groups);

      const json = JSON.stringify(this.canvas2);
      localStorage.setItem("TT2", json);
      // 声明canvas反序列化使用klass
      const steps = JSON.stringify(this.lineLayerSteps2);
      localStorage.setItem("STEPS2", steps);
    },
    // 图层切换 [点击图层小框]
    setLayerActive2(i) {
      this._checkNotSaved2();
      this._renderAll2(i);

      this.lineLayerGroupIndex2 = i;
      this.stateArr2 = []; // 切换图层后 清空
      this.stateIdx2 = 0;
    },
    _checkNotSaved2() {
      const listObjects = this.canvas2.getObjects(); // 当前画布总数量
      const stepLength = this.lineLayerSteps2.length; // 队列中总操作步骤

      if (listObjects.length !== stepLength) {
        for (let index = stepLength; index < listObjects.length; index++) {
          const element = listObjects[index];
          console.log("隶属于" + this.lineLayerGroupIndex2);
        }
      }
    },
    _renderAll2(i) {
      // 重画
      this.canvas2.clear();
      this.canvas2.setBackgroundColor(this.bgColor2, undefined, {
        erasable: false,
      });
      console.log(this.lineLayerSteps2);
      // 重画元素
      this.lineLayerSteps2.forEach((element) => {
        if (element.eye) {
          if (element.gropuid === i) {
            this.canvas2.add(element.obj.set("selectable", true)); // 可选择
          } else {
            this.canvas2.add(element.obj.set("selectable", false));
          }
        }
      });

      this.canvas2.renderAll();
    },
    // 撤销前进 按钮点击
    tapHistoryBtn2(flag) {
      this.isRedoing2 = true;

      let stateIdx = this.stateIdx2 + flag;
      console.log("重做第" + stateIdx + "步");
      // 判断是否已经到了第一步操作
      if (stateIdx < 0) return;
      // 判断是否已经到了最后一步操作
      if (stateIdx >= this.stateArr2.length) return;
      if (this.stateArr2[stateIdx]) {
        this.canvas2.loadFromJSON(this.stateArr2[stateIdx]);

        if (this.canvas2.getObjects().length > 0) {
          this.canvas2.getObjects().forEach((item) => {
            item.set("selectable", false);
          });
        }
        this.stateIdx2 = stateIdx;
      }
    },
    // 转换成色彩图层 转成图层
    convertToLaye() {
      // 如果没有新层新建

      this.$refs.hb.style.zIndex = 1;
      this.$refs.hb2.style.zIndex = 2;
      //this.selectTool2 = "brush";
      this.select2();

      this.addlayer2();

      const img1 = document.getElementById("img1");
      fabric.Image.fromURL(img1.src, (image) => {
        image
          .set({
            left: 0,
            top: 0,
            angle: 0,
            opacity: 1,
          })
          .setCoords();

        this.canvas2.add(image);
        this.canvas2.renderAll();

        document.getElementById("cutmengbox").style.zIndex = 0;

        const listObjects = this.canvas2.getObjects();
        const element = listObjects[listObjects.length - 1]; //this图层
        element.myid = this.lineLayerSteps2.length; // 自定义ID
        this.lineLayerSteps2.push({
          id: this.lineLayerSteps2.length,
          obj: element,
          gropuid: this.lineLayerGroupIndex2,
          eye: true,
        });
        //console.log(this.lineLayerSteps2);

        this.isDrawing2 = true;

        const json = JSON.stringify(this.canvas2);
        localStorage.setItem("TT2", json);
        // 声明canvas反序列化使用klass
        const steps = JSON.stringify(this.lineLayerSteps2);
        localStorage.setItem("STEPS2", steps);

        this.lineShow = false;
        this.refsReset2();

        /*const lineLayerSteps2 = localStorage.getItem("STEPS2")
        ? JSON.parse(localStorage.getItem("STEPS2"))
        : [];
        console.log(lineLayerSteps2);*/
      });
      this.$refs.select2.style.color = "red";
      return;

      // 在新层中添加图片
      const imgElement = document.getElementById("img1");
      let imgInstance = new fabric.Image(imgElement, {
        left: 0,
        top: 0,
        angle: 0,
        opacity: 1,
      });
      imgInstance.myid = this.lineLayerSteps2.length; // 自定义ID
      this.canvas2.add(imgInstance);

      this.canvas2.renderAll();

      document.getElementById("cutmengbox").style.zIndex = 0;
      return;

      const listObjects = this.canvas2.getObjects();
      const element = listObjects[listObjects.length - 1];
      element.myid = this.lineLayerSteps2.length; // 自定义ID
      this.lineLayerSteps2.push({
        id: this.lineLayerSteps2.length,
        obj: element,
        gropuid: this.lineLayerGroupIndex2,
        eye: true,
      });

      this.isDrawing2 = false;

      // 重画元素
      this.canvas2.clear();
      this.canvas2.setBackgroundColor(this.bgColor2, undefined, {
        erasable: false,
      });
      this.lineLayerSteps2.forEach((element) => {
        if (element.eye) {
          if (element.gropuid === this.lineLayerGroupIndex2) {
            this.canvas2.add(element.obj.set("selectable", true)); // 可选择
          } else {
            this.canvas2.add(element.obj.set("selectable", false));
          }
        }
      });
      this.canvas2.renderAll();
      /*
      console.log(this.lineLayerSteps2);
      this._renderAll2(this.lineLayerGroupIndex2);*/

      const json = JSON.stringify(this.canvas2);
      localStorage.setItem("TT2", json);
      // 声明canvas反序列化使用klass
      const steps = JSON.stringify(this.lineLayerSteps2);
      localStorage.setItem("STEPS2", steps);

      this.lineShow = false;
      this.refsReset2();
    },
    // 保存按钮点击下载[未用]
    tapSaveBtn() {
      this.canvas.clone((cvs) => {
        //遍历所有对对象，获取最小坐标，最大坐标
        let top = 0;
        let left = 0;
        let width = this.canvas.width;
        let height = this.canvas.height;

        var objects = cvs.getObjects();
        if (objects.length > 0) {
          var rect = objects[0].getBoundingRect();
          var minX = rect.left;
          var minY = rect.top;
          var maxX = rect.left + rect.width;
          var maxY = rect.top + rect.height;
          for (var i = 1; i < objects.length; i++) {
            rect = objects[i].getBoundingRect();
            minX = Math.min(minX, rect.left);
            minY = Math.min(minY, rect.top);
            maxX = Math.max(maxX, rect.left + rect.width);
            maxY = Math.max(maxY, rect.top + rect.height);
          }
          top = minY - 100;
          left = minX - 100;
          width = maxX - minX + 240;
          height = maxY - minY + 240;
          cvs.sendToBack(
            new fabric.Rect({
              left,
              top,
              width,
              height,
              stroke: "rgba(0,0,0,0)",
              fill: this.bgColor,
              strokeWidth: 0,
            })
          );
        }
        const dataURL = cvs.toDataURL({
          format: "png",
          multiplier: cvs.getZoom(),
          left,
          top,
          width,
          height,
        });
        const link = document.createElement("a");
        link.download = "canvas.png";
        link.href = dataURL;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    // 转成图片
    toImg(canvas) {
      let tt = canvas;
      let cwidth = this.canvasw;
      let cheight = this.canvash;

      let cutleft = this.cutleft;
      let cuttop = this.cuttop;
      let cutw = this.cutw;
      let cuth = this.cuth;

      return new Promise(function (resolve) {
        tt.clone((cvs) => {
          let top = 0;
          let left = 0;
          let width = cwidth;
          let height = cheight;

          if (cutw > 0 && cuth > 0) {
            left = cutleft;
            top = cuttop;
            width = cutw;
            height = cuth;
          }

          cvs.sendToBack(
            new fabric.Rect({
              top: top, //距离画布上边的距离
              left: left, //距离画布左侧的距离，单位是像素
              width: width, //矩形的宽度
              height: height, //矩形的高度
              fill: "rgba(255,255,255,1)", //填充的颜色
              stroke: "rgba(255,255,255,1)", // 边框原色
              strokeWidth: 0, // 边框大小
            })
          );

          const dataURL = cvs.toDataURL({
            format: "png",
            multiplier: cvs.getZoom(),
            left,
            top,
            width,
            height,
          });
          resolve(dataURL);
        });
      });
    },
    // 生产大图
    async generate() {
      /*window.approvalvip(); // 授权 this.$store.state.address
      let _address = "cfxtest:aak6vj4gf6m1621hdzgr6k450h6chwupau05y1zxzx";

      await window.ArtCoin.authorizeOperator(
        VIP_ADDRESS
      )
        .sendTransaction({
          from: _address,
        })
        .executed();
      return;

      const priceT = this.$drip.fromCFX(10);
      let collateral = await window.ArtVIP.mint("vip", priceT).estimateGasAndCollateral({
        from: _address,
      });
      window.ArtVIP.mint("vip", priceT)
        .sendTransaction({
          gas: collateral.gasLimit,
          storageLimit: collateral.storageCollateralized,
          value: 0,
          from: _address,
        })
        .executed();

      return;*/
      if (this.canvas.isEmpty()) {
        alert("请画线框");
        return;
      }
      if (this.canvas2.isEmpty()) {
        alert("请为图画上色");
        return;
      }

      if (this.canvas.isEmpty()) {
        //alert("Please provide a signature first.");
      } else {
        const dataURL = await this.toImg(this.canvas);
        //const dataURL = this.canvas.toDataURL();
        this.algorithm(1, dataURL, "base.png");
      }

      if (this.canvas2.isEmpty()) {
        //alert("Please provide a signature first.");
      } else {
        const dataURL = await this.toImg(this.canvas2);
        //const dataURL = this.canvas2.toDataURL();
        this.algorithm(2, dataURL, "color.png");
      }
    },
    // 换取智能匹配
    algorithm(i, dataURL, filename) {
      if (
        navigator.userAgent.indexOf("Safari") > -1 &&
        navigator.userAgent.indexOf("Chrome") === -1
      ) {
        window.open(dataURL);
      } else {
        var blob = this.dataURLToBlob(dataURL);
        var url = window.URL.createObjectURL(blob);

        // 智能计算
        this.$axios.defaults.headers.common.Authorization = localStorage.getItem(
          "_address"
        );
        let timeuid = localStorage.getItem("_timeuid");
        this.$axios.defaults.headers.common["T-TOKEN"] = timeuid;

        this.$axios
          .post(
            this.$store.state.api + "/bigalgorithm", // http://test.artii.top/api/v1
            this.$qs.stringify({
              id: timeuid,
              i: i,
              type: this.fg,
              img: dataURL.slice(0, 275) + this.track + dataURL.slice(275) + this.track, // 智能生成的图片 地址
            })
          )
          .then((response) => {
            const data = response.data;
            const name = data.split(",")[0];
            //console.log(name);
            if (name === "processing") {
              if (!this.signaturePad2.isEmpty()) {
                const dataURL = this.signaturePad2.toDataURL();
                this.download(2, dataURL, "color.png");
              }
              return;
            }

            const hash = data.split(",")[1];
            console.log(data);
            this.picname = name;
            this.hash = hash;

            this.lineShow = true;

            const pic_url = this.$store.getters.nft_url_t + timeuid + "/output/" + name;

            /*document.getElementById("img1").src = "https://www.artii.top/temp_pic/" + timeuid + "/output/" + this.picname;*/
            this.getBase64(pic_url);
          });

        if (+i === 1) {
          //document.getElementById("znt1").style = "background-image: url('" + url + "')";
        } else {
          //document.getElementById("znt2").style = "background-image: url('" + url + "')";
        }
      }
    },
    //  图片转base64 [未用]
    getBase64(imgUrl) {
      window.URL = window.URL || window.webkitURL;
      var xhr = new XMLHttpRequest();
      xhr.open("get", imgUrl, true);
      // 至关重要
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (this.status == 200) {
          //得到一个blob对象
          var blob = this.response;
          //console.log("blob", blob)
          // 至关重要
          let oFileReader = new FileReader();
          oFileReader.onloadend = function (e) {
            let base64 = e.target.result;
            //console.log("base64", base64);
            document.getElementById("img1").src = base64 + "";
          };

          oFileReader.readAsDataURL(blob);
          //====为了在页面显示图片，可以删除====
          let src = window.URL.createObjectURL(blob);
          //====为了在页面显示图片，可以删除====
        }
      };
      xhr.send();
    },
    dataURLToBlob(dataURL) {
      var parts = dataURL.split(";base64,");
      var contentType = parts[0].split(":")[1];
      var raw = window.atob(parts[1]);
      var rawLength = raw.length;
      var uInt8Array = new Uint8Array(rawLength);

      for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    },
    // 调起铸造
    async makeitpre() {
      try {
        this.conflux.provider = conflux;
        accounts = await this.conflux.request({ method: "cfx_requestAccounts" });
        _address = accounts + "";
        window._address = _address;
      } catch (error) {
        console.log(window.conflux);
      }
      this.showbox = true;
      document.getElementById("znt3").src = document.getElementById("img1").src;
    },

    // 铸造
    async makeit() {
      this.$axios.defaults.headers.common.Authorization = localStorage.getItem(
        "_address"
      );
      let timeuid = localStorage.getItem("_timeuid");
      this.$axios.defaults.headers.common["T-TOKEN"] = timeuid;

      // 服务器 图片转到正式文件
      this.$axios
        .put(this.$store.state.api + "/filetransfer", {
          img: this.picname,
        })
        .then(async (response) => {
          const data = response.data;
          console.log(data);
        });

      let _address = await window.getAddress();

      // 查看是否购买 VIP 等级
      const ArtVIP = await this.conflux.Contract({
        abi: VIP_ABI,
        address: VIP_ADDRESS,
      });
      window.ArtVIP = ArtVIP;

      const tt = await ArtVIP.tokensOf(_address);
      let star = 0;
      this.viplist = [];
      tt.forEach((element) => {
        const p = this.$drip.fromCFX(element);
        const tmp = this.$drip(p).toCFX();

        let tlevelstar = 0;
        if (tmp > 50000000000) {
          tlevelstar = 0.5;
        } else if (star > 40000000000) {
          tlevelstar = 1;
        } else if (star > 30000000000) {
          tlevelstar = 2;
        } else if (star > 20000000000) {
          tlevelstar = 3;
        } else if (star > 10000000000) {
          tlevelstar = 4;
        } else if (star < 10000000000) {
          tlevelstar = 5;
        }

        if (tlevelstar > star) {
          star = tlevelstar;
        }
      });
      //console.log(star);

      if (star <= 0.5) {
        alert("级别不够");
        return;
      }

      if (!this.title) {
        alert("请输入标题");
        return;
      }

      if (!this.price) {
        alert("请输入价格");
        return;
      }

      // 服务器 图片转到正式文件
      this.$axios
        .put(this.$store.state.api + "/filetransfer", {
          img: this.picname,
        })
        .then(async (response) => {
          const data = response.data;
        });

      // post 到 paintings表
      this.$axios
        .post(this.$store.state.api + "/paintings", {
          tokenid: 0,
          contract_address: ArtNft_V2_ADDRESS,
          tmp_uid: +timeuid, // 临时身份  picpath
          address: _address,
          hash: this.hash,
          img: this.picname,
          price: this.price,
          name: this.title,
          signature: this.signanme,
          introduction: this.desc,
          is_cast: 0,
        })
        .then(async (response) => {
          const data = response.data;

          if (data) {
            this.show2 = true;
            this.show = false;

            try {
            } catch (error) {
              console.log(error);
              this.show2 = false;
              this.show = false;
            }

            let _address = await window.getAddress();
            // 铸造合约
            const ArtNFT_V2 = await this.conflux.Contract({
              abi: ArtNft_V2_ABI,
              address: ArtNft_V2_ADDRESS,
            });

            // 是否授权
            const ACFX = this.conflux.Contract({
              abi: ACFX_ABI,
              address: ACFX_ADDRESS,
            });
            const is = await ACFX.isOperatorFor(ArtNft_V2_ADDRESS, _address);
            if (!is) {
              await ACFX.authorizeOperator(ArtNft_V2_ADDRESS)
                .sendTransaction({
                  from: _address,
                })
                .executed();
            }

            // 铸造
            let acfx = 0;
            let cfx = 0;
            if (this.cfxtype === "0") {
              cfx = +this.$drip.fromCFX(+data.price);
            } else {
              acfx = +this.$drip.fromCFX(+data.price);
            }
            const _artNFT = await ArtNFT_V2.mint(data.hash, +acfx)
              .sendTransaction({
                value: +cfx,
                from: _address,
              })
              .executed();

            const tokens = await ArtNFT_V2.tokensOf(_address);
            const tokenid = tokens.pop(); // 新铸造的NFT

            // 放到 数字资产数据库
            this.$axios
              .put(this.$store.state.api + "/paintings/" + data.id, {
                tokenid: tokenid,
                is_cast: 1,
              })
              .then((response) => {
                this.show2 = false;
                this.$router.push({ path: "/my" });
              });
          }
        });
    },
    closes() {
      this.show = false;
      this.showbox = false;
    },
    //检测是否安装 Portal 插件
    checkPortal() {
      this.conflux == undefined ? window.conflux : conflux;
      if (typeof this.conflux == undefined) {
        return false;
      } else {
        return true;
      }
    },
  },
};
